ion-footer {
  background: var(--ion-background-color);
  padding: 16px 0 24px;

  ion-toolbar {
    --background: transparent;
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;

    padding: 0;

    ion-button:only-of-type {
      width: 100%;
      padding-left: var(--pro-content-padding);
    }
  }
}

ion-content + ion-footer {
  background: transparent;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    backdrop-filter: blur(7.5px);
  }
}

ion-footer.footer-ios ion-toolbar:first-of-type {
  --border-width: 0;
}
