ion-popover.pro-popover {
  --backdrop-opacity: var(--ion-backdrop-opacity);

  overflow-y: scroll;

  &::part(content) {
    right: 0;
    left: auto !important;
    width: 93.4%;
    overflow: hidden;
    border-radius: 20px 0 0;
    background-color: var(--ion-item-background);
  }
}
