/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Swiper */
@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import '@ionic/angular/css/ionic-swiper.css';
@import 'assets/scss/slides';

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Basic SCSS */
@import 'assets/scss/fonts';
@import 'assets/scss/buttons';
@import 'assets/scss/form';
@import 'assets/scss/header';
@import 'assets/scss/footer';
@import 'assets/scss/fab-button';
@import 'assets/scss/popover';
@import 'assets/scss/calendar';
@import 'assets/scss/list';
@import 'assets/scss/alert';
@import 'assets/scss/modal';
@import 'assets/scss/surveyjs';
@import 'assets/scss/card';
@import 'assets/scss/chat';
@import 'assets/scss/searchbar';

ion-app {
  background-color: var(--ion-background-color);
  /* stylelint-disable-next-line function-no-unknown */
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  > ion-router-outlet {
    transition: top 0.25s ease;
  }
}

html {
  font-size: 17px;
}

/* stylelint-disable */
@supports (font: -apple-system-body) {
  html {
    font: -apple-system-body !important;
  }
}
/* stylelint-enable */

body {
  background: var(--pro-color-white);
  font-family: Gilroy, sans-serif;
}

:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
h2,
h3 {
  margin-bottom: 20px;
}

/* 28px / 31.3px */
h1 {
  font-size: 1.65rem;
  line-height: 2.02rem;
}

/* 24px / 29px */
h2 {
  font-size: 1.41rem;
  line-height: 1.7rem;
  margin-bottom: 24px;
}

/* 19px / 22px */
h3 {
  font-size: 1.12rem;
  line-height: 1.3rem;
  margin-top: 8px;
  margin-bottom: 16px;
}

a {
  text-decoration: none;
  color: var(--ion-color-primary);
}

/* 17px / 21px */
p {
  @include body-text;

  margin: 0;
  margin-bottom: 16px;
  padding-right: 24px;

  /* 14px / 16.5px */
  &.small {
    font-size: 0.83rem !important;
    line-height: 1rem !important;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }

  &.bold {
    font-weight: 700 !important;
  }
}

ul,
ol {
  @include body-text;

  --padding-start: 24px;
  --padding-end: 24px;

  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 0;

  li {
    margin-bottom: 16px;
  }
}

ul {
  li::marker {
    color: var(--ion-color-secondary);
  }
}

ion-content {
  --padding-start: 6.6%;
  --padding-end: 0;
  --padding-top: 24px;
  --padding-bottom: 24px;

  &.ion-padding-end {
    --padding-end: 6.6%;
  }

  &.no-scroll {
    --overflow: hidden;
  }
}

.manual-measurement-cta-visible {
  ion-content {
    --padding-bottom: 82px;
  }
}

ion-content::part('scroll') {
  scrollbar-width: none;
}

ion-content::part(scroll) {
  scrollbar-width: none;
}

ion-action-sheet {
  button {
    &.warning {
      --button-color: var(--ion-color-warning);
    }
  }

  &.no-emphasize.action-sheet-selected.sc-ion-action-sheet-ios {
    font-weight: normal;

    &::after {
      opacity: 0;
    }
  }
}

ion-item {
  &.no-activated-background {
    --background-activated-opacity: 0;
  }

  &.no-min-height {
    --min-height: none;
  }

  &.lines-t-b {
    --border-width: 0.55px 0 0.55px 0 !important;
  }
}

ion-modal {
  ion-header {
    h1 {
      margin: 0;
    }

    h3 {
      margin-top: 0;
      font-weight: normal;
    }
  }
}

ion-segment-button {
  --color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary);
  --indicator-color: var(--ion-color-primary);
  --indicator-height: 1px;
  --padding-top: 0;
  --padding-bottom: 4px;
  --padding-start: 4px;
  --padding-end: 12px;
  --border-width: 0 0 0.55px 0;
  --border-style: solid;
  --border-color: var(--ion-border-color);

  text-transform: none;
  font-size: 0.82rem;
  line-height: 0.97rem;
  min-height: 10px;
  letter-spacing: 0;

  ion-label {
    --padding-end: 12px;

    align-self: flex-start;
  }

  &.segment-button-checked {
    --padding-bottom: 3px;

    font-weight: 600;
  }
}

.header-md::after {
  background-image: none;
}

.padding-0 {
  --padding-start: 0;
  --padding-end: 0;

  padding-left: 0;
  padding-right: 0;
}

.padding-small {
  --padding-start: 4px;
  --padding-end: 4px;

  padding-left: 4px;
  padding-right: 4px;
}

.padding-t-0 {
  --padding-top: 0;

  padding-top: 0;
}

.padding-b-0 {
  --padding-bottom: 0;

  padding-bottom: 0;
}

.padding-r-0 {
  --padding-end: 0;
  --inner-padding-end: 0;

  padding-right: 0;
}

.padding-r {
  --padding-end: 6.6%;

  padding-right: 6.6%;
}

.padding-l {
  --padding-start: 6.6%;

  padding-left: 6.6%;
}

.padding-l-0 {
  --padding-start: 0;

  padding-left: 0;
}

.margin-0 {
  margin: 0;
}

.margin-b {
  margin-bottom: 24px;
}

.margin-t {
  margin-top: 5px;
}

.margin-t-l {
  margin-top: 20px;
}

.margin-t-0 {
  margin-top: 0;
}

.margin-b-0 {
  margin-bottom: 0;
}

/**
forms, inputs
*/
.pro-suffix-label {
  margin-top: 24px;
}

.form-error {
  display: block;
  padding: 4px 8px 8px 0;
  font-weight: 700;
  font-size: 0.82rem;
  line-height: 0.97rem;
  letter-spacing: 0.24px;
  color: var(--ion-color-warning);
}

.modal-header {
  h1 {
    margin-bottom: 0;
    margin-top: 16px;
    font-size: 1.12rem;
    line-height: 1.3rem;
  }

  h3 {
    font-size: 0.82rem;
    line-height: 0.97rem;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 0;
  }
}

.uppercase {
  text-transform: uppercase;
}

.color-warning {
  color: var(--ion-color-warning);
}

.pro-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 1rem;
  line-height: 1.24rem;
}

.placeholder {
  opacity: 0.33;
  font-weight: 500;
}

.size-s {
  font-size: 0.82rem;
  line-height: 0.97rem;
}

.flex {
  display: flex;
}

.color-secondary {
  color: var(--ion-color-secondary);
}

.sc-ion-action-sheet-ios-h {
  --button-color: var(--ion-text-color);
  --color: var(--ion-text-color);
  --button-color-activated: var(--ion-text-color);
  --button-background: var(--ion-item-background);
  --button-background-selected: var(--ion-item-background);

  .action-sheet-button {
    border-bottom: var(--pro-default-border);

    &:last-of-type {
      border: none;
    }

    &.emphasize {
      font-weight: 600;
    }
  }

  .action-sheet-selected {
    &::after {
      background: inherit;
      opacity: 0;
    }
  }

  .remove-selected-option {
    font-weight: inherit;
    color: var(--pro-color-blue-velvet);
  }
}

.picker-suffix {
  flex: 0 0 auto;
}

.list-headline {
  font-size: 1rem;
  line-height: 1.24rem;
  margin: 8px 0;
  font-weight: normal;
}

.app-version {
  font-size: 0.82rem;
  line-height: 0.97rem;
  color: var(--ion-text-color);
  opacity: 0.75;
  margin: 16px 0;
}

/* TO SORT */
.block {
  display: block;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 900 !important;
}

.ion-text-wrap.sc-ion-label-ios-h,
[text-wrap].sc-ion-label-ios-h {
  font-size: 1rem;
  line-height: 1.24rem;
}

::slotted([slot='start']) {
  margin: 0;
  margin-inline: 0 16px;
}
