.pro-alert {
  --background: var(--ion-item-background);
  --max-width: 100%;
  --width: 100%;

  .alert-wrapper {
    margin-left: 6%;
    border-radius: 20px 0 0;
  }

  .alert-head {
    padding: 24px 24px 16px;
    text-align: left;
  }

  .alert-title {
    /* 19px / 22px */
    font-size: 1.12rem;
    line-height: 1.3rem;
    font-weight: 700;
    margin: 0;
  }

  .alert-message {
    @include body-text;

    padding: 0 24px 24px;
    text-align: left;
    max-height: 60vh;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .alert-button-group {
    .alert-button {
      @include modal-bottom-buttons;

      flex: 1;
    }
  }

  .alert-icon {
    font-size: 180px;
  }

  &.warning {
    .alert-button:last-of-type {
      background-color: var(--ion-color-warning);
      color: var(--ion-color-warning-contrast);
    }
  }

  &.success {
    .alert-title {
      color: var(--ion-color-secondary);
    }

    .alert-head,
    .alert-message {
      text-align: center;
    }

    .alert-message img {
      margin: 16px 0;
    }
  }

  &.spinner {
    ion-spinner {
      display: block;
      margin: 0 auto;
      color: var(--ion-color-primary);
      width: 48px;
      height: 48px;
    }
  }
}
