.swipe-options-list {
  contain: none;

  ion-item-sliding {
    overflow: visible;
  }
}

.list-ios,
.list-md {
  box-shadow: var(--pro-default-shadow);
  border-radius: 20px 0 0;
  overflow: hidden;
}

@mixin input-label {
  font-size: 0.82rem !important;
  line-height: 0.97rem !important;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: var(--ion-margin);
  white-space: normal !important;
  word-break: break-word;
  color: var(--ion-text-color);
}

ion-item {
  --inner-padding-top: 0;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --padding-top: 16px;
  --padding-end: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --min-height: none;

  .label-stacked.sc-ion-label-ios-h {
    flex: initial;

    &.no-text-transform {
      text-transform: none;
    }

    /* multiple labels in an item. Reset second label to reg-font-style */
    &:nth-of-type(2) {
      @include body-text;

      text-transform: none;
      margin: 0;
    }
  }

  &::part(detail-icon) {
    opacity: 1;
    stroke-width: 1px;
    align-self: center;
  }

  &.item-interactive {
    --inner-padding-bottom: 0;
    --padding-bottom: 0;

    &:not(.no-min-height) {
      --min-height: 70px;
    }
  }

  &.item-lines-inset {
    --padding-start: 0;
    --inner-border-width: 0 !important;
    --border-width: 0 0 0.55px 0 !important;

    margin-left: 16px;
  }

  &.checkbox-item {
    --padding-bottom: 16px;
    --min-height: none;

    .sc-ion-label-ios-h {
      @include body-text;

      margin-bottom: 0;
      text-transform: none;
    }
  }

  &.centered-labels {
    .label-stacked.sc-ion-label-ios-h:first-of-type {
      margin-top: auto;
    }

    .label-stacked.sc-ion-label-ios-h:last-of-type {
      margin-bottom: auto;
    }
  }

  &.transparent {
    --background: transparent;
  }

  &.item-radio-checked {
    .sc-ion-label-md-h,
    .sc-ion-label-ios-h {
      color: var(--ion-color-primary);
      font-weight: 700 !important;
    }
  }

  ion-avatar {
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ion-icon {
      font-size: 25px;
    }

    + .sc-ion-label-ios-h:only-of-type {
      @include body-text;

      color: var(--color);
      margin: 0;
      margin-left: var(--ion-margin);
      text-transform: none;
    }
  }

  ion-note {
    @include body-text;
  }
}

ion-list-header {
  padding: 0;

  ion-label {
    font-size: 1.12rem;
    line-height: 1.3rem;
    font-weight: 700;
    color: var(--ion-text-color);
    margin: 16px 16px 16px 0;
    word-break: break-word;
  }
}

ion-list {
  margin-bottom: 32px;

  &.fab-list-margin {
    margin-bottom: 150px;
  }

  ion-label {
    @include input-label;

    hyphens: auto;

    &.large {
      font-size: 1rem !important;
      line-height: 1.24rem !important;
    }

    &.bold {
      font-weight: 700 !important;
    }
  }

  ion-radio-group {
    ion-item {
      --padding-top: 12px;
      --padding-bottom: 12px;
    }

    ion-label {
      text-transform: none;
      margin: 0;
    }
  }

  &.transparent {
    background: transparent;
    box-shadow: none;
    border-radius: 0;

    ion-item {
      --background: transparent;
    }

    ion-radio-group ion-item {
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;

      margin-bottom: 24px;
    }
  }
}

ion-label .optional {
  text-transform: none;
  font-weight: normal;
  float: right;
  color: var(--ion-color-shade);
}

/* remove top-margin, if list-header is first content child */
ion-content > ion-list-header:first-child {
  ion-label {
    margin-top: 0;
  }
}

.item-native {
  min-height: none;
}
