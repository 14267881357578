/*** reset default calendar-styles */
.cal-header {
  padding: 12px 0 4px;
  text-transform: uppercase;
  font-size: 10pt;
  line-height: 10pt;

  .cal-cell {
    background-color: transparent !important;
    outline: none;
  }
}

.cal-month-view {
  background-color: transparent;
}

.cal-days,
.cal-cell-row {
  border: none !important;
  min-height: auto !important;
}

.cal-cell-row {
  background-color: transparent !important;
}

/*** custom cell template */
.cal-cell-custom {
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  padding: 2px 0;
  border: 3px solid transparent;
}

.cal-day-cell {
  &:hover,
  &:focus {
    outline: none;
  }

  border: none !important;
  min-height: auto !important;

  .cal-day-date {
    /* uses fixed font settings to avoid incorrect display of the calendar */
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    float: none;
    margin: 0;
    text-align: center;
    display: block;
    background: transparent;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    padding: 5px 0;
    white-space: nowrap;
  }

  &.cal-day-selected {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }

    .cal-cell-custom {
      border: 3px solid var(--ion-color-success);
      border-radius: 5px;
    }
  }

  &.cal-today {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }

    .cal-day-date {
      padding: 2px 8px;
      font-size: 15px;
      background-color: var(--ion-color-primary);
      color: var(--pro-color-white);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1 !important;
    }
  }

  &.cal-out-month {
    .cal-day-date {
      opacity: 0.3;
    }
  }

  &.cal-disabled,
  &.cal-unloaded {
    opacity: 0.2;

    .cal-day-date {
      opacity: 1;
    }

    &:hover {
      background-color: inherit !important;
    }
  }
}

.cal-cell-custom-additional-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  flex: 0 0 14px;

  ion-icon {
    padding: 0 2px;
    font-size: 12px;

    &.appointments {
      width: auto;
      height: 18px;
      max-width: none;
      max-height: none;
      pointer-events: none;
    }

    &.documents {
      padding-top: 2px;
      pointer-events: none;
    }
  }
}

.cal-cell-custom-actions {
  width: calc(100% + 6px);
  flex: 0 0 10px;

  span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: transparent;
    margin-top: 2px;
  }

  .active {
    &.measurements {
      background-color: var(--ion-color-secondary);
    }

    &.medication-intakes {
      background-color: var(--ion-color-primary);
    }
  }
}
