ion-toolbar {
  --background: var(--ion-item-background);

  &.transparent {
    --background: transparent;
  }
}

ion-header {
  box-shadow: var(--pro-nav-bar-shadow);

  &.no-shadow {
    box-shadow: none;
  }

  ion-toolbar {
    --border-width: 0 !important;
    --padding-top: 0;
    --padding-end: 16px;
    --padding-bottom: 0;
    --padding-start: 16px;

    &:first-of-type {
      padding-top: 0;
    }
  }

  ion-back-button {
    --icon-margin-end: 0;
    --icon-margin-start: 0;

    font-size: 13px;
    color: var(--pro-color-black);
    width: 44px;
    height: 44px;
  }

  ion-button.header-save {
    font-size: 0.94rem;
    line-height: 1.15rem;
    font-weight: normal;
    margin: 0;
    color: var(--ion-color-primary);
    height: auto;
    text-transform: none;
    letter-spacing: normal;

    --color: var(--ion-color-primary);

    &::part(native) {
      padding: 0;
    }
  }
}

ion-title {
  font-size: 0.94rem;
  line-height: 1.15rem;
  font-weight: 800;
  padding: 0 16px;
}

/* fix notch-issues for web-preview */
.plt-desktop {
  ion-toolbar:first-of-type {
    padding-top: 20px;
  }

  --ion-safe-area-bottom: 22px;
}
