ion-button {
  font-size: 0.82rem;
  line-height: 0.97rem;
  font-weight: bold;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  height: auto;
  min-height: 50px;
  min-width: 50%;
  position: relative;
  outline: none;
  margin: 0;
  white-space: normal;

  --color: var(--pro-color-white);
  --padding-bottom: 17px;
  --padding-top: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-radius: 40px 0 0 40px;

  &::part(native) {
    min-height: 50px;
    line-height: 1rem;
  }

  &.left-top-border-radius {
    --border-radius: 24px 0 0 0;
  }

  &.button-outline {
    --background: var(--pro-color-white);
    --background-activated: var(--pro-color-white);
    --background-focused: var(--pro-color-white);
    --background-hover: var(--pro-color-white);
    --border-color: var(--ion-color-primary);
    --border-style: solid;
    --border-width: 2px 0 2px 2px;
    --color: var(--ion-color-primary);
    --padding-bottom: 15px;
    --padding-top: 14px;
  }

  &.button-clear {
    --color: var(--ion-text-color);
  }

  &.disabled {
    opacity: 0.5;
  }

  ion-spinner {
    color: var(--pro-color-white);
    position: absolute;
  }
}

ion-button.small {
  --border-radius: 30px;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 16px;
  --padding-end: 16px;

  min-width: unset;

  &::part(native) {
    min-height: 17px;
    height: auto;
    width: auto;
  }
}

/*
* buttons used on modal and popovers
* left one transparent (if present), right or only one is primary button
*/
@mixin modal-bottom-buttons {
  --background: transparent;
  --padding-bottom: 17px;
  --padding-top: 16px;
  --padding-start: 16px;
  --padding-end: 16px;

  background: transparent;
  border: none;
  flex: 0 0 50%;
  height: auto;
  color: var(--ion-text-color);
  font-size: 0.82rem;
  line-height: 0.97rem;
  font-weight: bold;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  min-height: 50px;
  min-width: 50%;
  position: relative;
  outline: none;
  margin: 0;
  white-space: normal;
  padding: 16px 16px 17px;

  &:last-of-type,
  &:only-of-type {
    border-radius: 24px 0 0;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    margin-left: auto;

    --border-radius: 24px 0 0 0;
  }

  &.warning {
    background-color: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
  }

  .alert-button-inner {
    min-height: auto;
  }
}

.back-button-disabled {
  opacity: 1;
}

button {
  padding: 0;
  background-color: transparent;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.button-row {
  position: relative;
  display: flex;
  width: 100%;
  text-align: right;
  margin: 32px 0;

  ion-button {
    width: 50%;

    &.light {
      --background: transparent;
      --background-activated: transparent;
      --border-radius: 0;

      color: var(--ion-text-color);
      right: 0;
    }
  }

  &.width-auto {
    ion-button {
      width: auto;
      min-width: auto;
    }
  }

  &.align-right {
    justify-content: end;
  }

  .margin-l-r {
    margin-right: 8px;
    margin-left: 8px;
  }
}

ion-button.button-round {
  --border-radius: 40px;
  --border-width: 2px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  --padding-end: 12px;

  min-height: auto;
  letter-spacing: 0.7px;

  &::part(native) {
    min-height: 41px;
    height: auto;
  }

  &.button-outline {
    --padding-top: 11px;
    --padding-bottom: 9px;
  }
}

.pdf-download-cta {
  display: flex;
  align-items: center;
  min-height: 75px;
  padding: 10px;
  padding-left: 58px;
  background-color: var(--pro-color-white);
  border: 2px solid var(--ion-color-primary);
  border-radius: 10px;
  color: var(--ion-color-primary);
  font-weight: bold;
  margin-bottom: 16px;
  cursor: pointer;
  background-image: url('/assets/images/pdf-download.png');
  background-position: 10px center;
  background-repeat: no-repeat;
}
