ion-modal.datetime-modal.modal-default,
ion-modal.datetime-modal.modal-default:not(.overlay-hidden) {
  --backdrop-opacity: 1 !important;
}

.floating-modal {
  --height: 50%;
  --min-height: 280px;

  .modal-wrapper {
    border-radius: 13px 0 0 13px;
    margin-left: 6.6%;
  }

  .textarea-wrapper {
    height: 100%;
  }

  .native-textarea {
    height: 100%;

    &:disabled {
      opacity: 1;
    }
  }
}

.pro-modal {
  ion-select::part('icon') {
    display: none;
  }

  .error {
    font-size: 12px;
    font-weight: 700;
    margin: 11px 22px 0;
  }
}

.info-modal {
  align-items: flex-end;
  justify-content: flex-end;

  --height: calc(100vh - 42px);
  --width: calc(100vw - 25px);

  &::part(content) {
    background: transparent;
  }
}

.message-toast {
  &::part(message) {
    white-space: pre-line;
  }
}

.modal-sheet {
  --border-radius: 20px;

  ion-content {
    --background: var(--ion-color-white);
  }

  &::part(handle) {
    background: var(--ion-color-light);
    width: 44px;
    height: 4px;
    top: 8px;
    border-radius: 2px;
  }

  &::part(content) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
