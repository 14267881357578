ion-item {
  .sc-ion-input-ios-h,
  .sc-ion-input-ios-h.legacy-input {
    --placeholder-opacity: 0.33;

    align-items: flex-start;
  }
}

ion-input,
ion-textarea,
ion-datetime {
  @include body-text;

  width: 100%;

  &.readonly {
    color: var(--ion-color-medium);
  }
}

ion-datetime {
  --padding-top: 0;
  --padding-bottom: 0;

  flex: 1 1 auto;
}

ion-picker {
  /* fix touchstart event error */
  touch-action: none;
}

.sc-ion-picker-ios-h {
  --background: var(--ion-item-background);

  .picker-button {
    @include body-text;

    color: var(--ion-text-color);
  }
}

ion-input,
ion-select:not(.pro-select) {
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --placeholder-opacity: 0.33;

  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 1.24rem;
}

ion-select:not(.pro-select) {
  --padding-top: 0;
  --padding-bottom: 0;

  @include body-text;

  flex: 1 1 auto;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 22px;

  &.invisible {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: none;
  }
}

ion-select::part(text) {
  @include body-text;
}

ion-select:not(.pro-select)::part(icon) {
  display: none;
}

.pro-select {
  --padding-start: 15px;
  --padding-end: 20px;

  background-color: var(--pro-color-white);
  width: fit-content;
  height: 44px;

  &::part(icon) {
    display: none;
  }

  &::part(placeholder)::after,
  &::part(text)::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0;
    border-color: var(--pro-color-black) transparent transparent;
    position: absolute;
    top: 17px;
    right: 5px;
  }
}

.value-content {
  @include body-text;

  display: flex;
  flex: 1 1 auto;
  margin-bottom: var(--ion-margin);
}

ion-checkbox {
  --checkmark-width: 3;
  --size: 32px;
  --border-width: 2px;
  --border-color: var(--pro-color-black);
  --checkbox-background: transparent;
  --checkbox-background-checked: var(--ion-color-primary);
  --checkmark-color: var(--pro-color-white);

  &.checkbox-checked {
    --border-width: 0;

    &::part(mark) {
      /* hides default checkmark-icon */
      opacity: 0;
    }

    &::part(container) {
      background-image: url('/assets/svgs/checkbox_checkmark_light.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px auto;
    }
  }
}

/* exception for dark-mode necessary because svgs used as background images ignore variable color values */
.dark ion-checkbox {
  &.checkbox-checked {
    &::part(container) {
      background-image: url('/assets/svgs/checkbox_checkmark_dark.svg');
    }
  }
}

ion-radio {
  --color: var(--pro-color-black);

  margin: 0;
  margin-inline-end: 12px;
  width: 32px;
  height: 32px;

  &.radio-checked::part(mark) {
    width: 18px;
    height: 18px;
  }
}
