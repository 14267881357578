@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-ExtraBold.otf');
  font-weight: 900;
  font-style: normal;
}

/* global body-text style
   17px / 21px */
@mixin body-text {
  font-size: 1rem !important;
  line-height: 1.24rem !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
}

.body-text {
  @include body-text;
}
